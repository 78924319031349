import React from "react";
import { Navigate } from "react-router-dom";

import PrivateLayout from "../layouts/private/Private";
import useCheckUserPermission from "../providers/useCheckUserPermission";

let isLoggedIn = localStorage.getItem("token");

const PrivateRoute = (props) => {
	const { component: Component, moduleCode, moduleName } = props;

	const { dataPermissions, refetchPermissions } = useCheckUserPermission(
		moduleName,
		moduleCode
	);

	if (isLoggedIn) {
		return (
			<PrivateLayout
				{...props}
				dataPermissions={dataPermissions ? dataPermissions.data : []}
				refetchPermissions={refetchPermissions}
			>
				<Component
					{...props}
					dataPermissions={dataPermissions ? dataPermissions.data : []}
					refetchPermissions={refetchPermissions}
				/>
			</PrivateLayout>
		);
	} else {
		return <Navigate to="/" />;
	}
};

export default PrivateRoute;
