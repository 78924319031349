import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faArrowLeft,
	faBars,
	faPlus,
	faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import {
	Button,
	Card,
	Checkbox,
	Col,
	Form,
	Radio,
	Row,
	Space,
	Typography,
	notification,
} from "antd";

import { GET, POST } from "../../../providers/useAxiosQuery";
import validateRules from "../../../providers/validateRules";
import FloatInput from "../../../providers/FloatInput";
import FloatQuill from "../../../providers/FloatQuill";
import ModalFormQuestionTypeOption from "./components/ModalFormQuestionTypeOption";
import notificationErrors from "../../../providers/notificationErrors";

export default function PageEvaluationCategoryForm() {
	const navigate = useNavigate();
	const params = useParams();

	const [
		toggleModalFormQuestionTypeOption,
		setToggleModalFormQuestionTypeOption,
	] = useState({
		open: false,
		key: null,
	});

	const [dataSource, setDataSource] = useState();

	const [form] = Form.useForm();

	if (params.category_id) {
		GET(
			`api/form_question_category/${params.category_id}`,
			"form_question_category_info",
			(res) => {
				console.log("form_question_category", res);
				if (res.data) {
					let form_questions = res.data.form_questions.map((item) => {
						return {
							...item,
							with_attachment: item.with_attachment === 1 ? true : false,
							required: item.required === 1 ? true : false,
						};
					});
					let data = {
						...res.data,
						form_questions,
					};
					form.setFieldsValue(data);
					setDataSource(data);
				}
			}
		);
	}

	const dragItem = useRef();
	const dragOverItem = useRef();

	const dragStart = (e, position) => {
		dragItem.current = position;

		let form_questions = form.getFieldValue("form_questions");
		if (form_questions.length > 0) {
			form_questions[position] = {
				...form_questions[position],
				grab: 1,
			};
			form.setFieldsValue({ form_questions });
		}
	};

	const dragEnter = (e, position) => {
		e.dataTransfer.dropEffect = "copy";
		dragOverItem.current = position;
	};

	const drop = (e) => {
		let copyListItems = [...form.getFieldValue("form_questions")];
		let dragItemContent = copyListItems[dragItem.current];
		copyListItems.splice(dragItem.current, 1);
		copyListItems.splice(dragOverItem.current, 0, dragItemContent);
		dragItem.current = null;
		dragOverItem.current = null;
		copyListItems = copyListItems.map((item) => ({ ...item, grab: 0 }));
		form.setFieldsValue({ form_questions: copyListItems });
	};

	const { mutate: mutateFormCategory, isLoading: isLoadingFormCategory } = POST(
		"api/form_question_category",
		"form_question_category_info"
	);

	const onFinish = (values) => {
		let form_questions = values.form_questions.map((item) => {
			let form_question_options = [];

			if (item.form_question_options && item.form_question_options.length > 0) {
				form_question_options = item.form_question_options.map((item2) => ({
					...item2,
					id: item2.id ?? "",
				}));
			}

			return {
				...item,
				with_attachment: item.with_attachment === true ? "1" : "0",
				required: item.required === true ? "1" : "0",
				id: item.id ?? "",
				form_question_options,
			};
		});

		let data = {
			category: values.category,
			form_id: params.id,
			id: params.category_id,
			form_questions,
		};
		mutateFormCategory(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Category",
						description: res.message,
					});
					navigate(`/evaluation/view/${params.id}`);
				} else {
					notification.error({
						message: "Category",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	const handleClickQuestionType = (key, type) => {
		let ifCon = type === "radio" || type === "checkbox" || type === "select";

		if (ifCon) {
			setToggleModalFormQuestionTypeOption({
				open: true,
				key,
			});
		}
	};

	useEffect(() => {
		const linkSurveyViewFn = () => {
			navigate(`/evaluation/view/${params.id}`);
		};
		let linkSurveyView = document.querySelector(".link_view_evaluation");
		if (linkSurveyView) {
			linkSurveyView.addEventListener("click", linkSurveyViewFn);
		}

		return () => {
			if (linkSurveyView) {
				linkSurveyView.removeEventListener("click", linkSurveyViewFn);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Form
			form={form}
			onFinish={onFinish}
			initialValues={{
				form_questions: [{}],
			}}
		>
			<Row gutter={[12, 12]}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Button
						icon={<FontAwesomeIcon icon={faArrowLeft} />}
						className="w-auto"
						onClick={() => navigate(-1)}
					>
						Back to list
					</Button>
				</Col>

				<Col xs={24} sm={24} md={24} lg={12} xl={12}>
					<Form.Item name="category" rules={[validateRules.required()]}>
						<FloatInput label="Category" placeholder="Category" />
					</Form.Item>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Card title="Questions" className="card-questions-wrapper">
						<Form.List name="form_questions">
							{(fields, { add, remove }) => (
								<>
									{fields.map(({ key, name, ...restField }) => {
										return (
											<Form.Item shouldUpdate noStyle key={key}>
												{() => {
													let grabStat = 0;

													let form_questions =
														form.getFieldValue("form_questions");

													if (form_questions && form_questions.length > 0) {
														if (
															form_questions[name] &&
															form_questions[name].grab
														) {
															grabStat = form_questions[name].grab;
														}
													}

													return (
														<div
															className={`form-list-item-wrapper ${
																grabStat === 1
																	? "form-list-item-wrapper-selected"
																	: ""
															}`}
															onDragStart={(e) => dragStart(e, key)}
															onDragEnter={(e) => dragEnter(e, key)}
															onDragEnd={drop}
															draggable
														>
															<div className="btn-order-wrapper">
																<Button
																	type="link"
																	icon={<FontAwesomeIcon icon={faBars} />}
																	className={`btn-drag ${
																		grabStat === 1
																			? "cursor-grabbing"
																			: "cursor-grab"
																	}`}
																/>
															</div>

															<div className="form-item-wrapper">
																<Row gutter={[20, 0]}>
																	<Col xs={24} sm={24} md={24} lg={24} xl={24}>
																		<Form.Item
																			{...restField}
																			name={[name, "question"]}
																			rules={[validateRules.required()]}
																			className="form-item-question"
																		>
																			<FloatQuill
																				label="Question"
																				placeholder="Question"
																				required
																			/>
																		</Form.Item>
																	</Col>
																	<Col xs={24} sm={24} md={24} lg={12} xl={12}>
																		<Form.Item
																			{...restField}
																			name={[name, "question_tips"]}
																			className="form-item-question-tips"
																		>
																			<FloatInput
																				label="Question Tips"
																				placeholder="Question Tips"
																			/>
																		</Form.Item>
																	</Col>
																	<Col xs={24} sm={24} md={24} lg={12} xl={12}>
																		<Form.Item
																			{...restField}
																			name={[name, "description"]}
																			className="form-item-description"
																		>
																			<FloatInput
																				label="Description"
																				placeholder="Description"
																			/>
																		</Form.Item>
																	</Col>
																	<Col xs={24} sm={24} md={24} lg={12} xl={12}>
																		<Form.Item
																			{...restField}
																			name={[name, "option_label"]}
																			className="form-item-option-label"
																		>
																			<FloatInput
																				label="Option Label"
																				placeholder="Option Label"
																			/>
																		</Form.Item>
																	</Col>
																	<Col xs={24} sm={24} md={24} lg={12} xl={12}>
																		<Form.Item
																			{...restField}
																			name={[name, "max_checkbox"]}
																			className="form-item-max-checkbox"
																		>
																			<FloatInput
																				label="Max Checkbox"
																				placeholder="Max Checkbox"
																				type="number"
																			/>
																		</Form.Item>
																	</Col>
																	<Col xs={24} sm={24} md={24} lg={24} xl={24}>
																		<Form.Item
																			{...restField}
																			name={[name, "with_attachment"]}
																			valuePropName="checked"
																			noStyle
																		>
																			<Checkbox className="mb-20">
																				With Attachment
																			</Checkbox>
																		</Form.Item>
																	</Col>
																	<Col xs={24} sm={24} md={24} lg={24} xl={24}>
																		<Form.Item
																			{...restField}
																			name={[name, "required"]}
																			valuePropName="checked"
																			noStyle
																		>
																			<Checkbox className="mb-20">
																				Required ?
																			</Checkbox>
																		</Form.Item>
																	</Col>
																</Row>

																<div className="ant-form-item-question-type">
																	<Typography.Text>
																		Question Type{" "}
																		<span className="danger-color">*</span>
																	</Typography.Text>
																	<Form.Item
																		{...restField}
																		name={[name, "question_type"]}
																		rules={[validateRules.required()]}
																	>
																		<Radio.Group>
																			<Space direction="vertical">
																				<Radio value="text">Text (Input)</Radio>
																				<Radio value="number">
																					Number (Input)
																				</Radio>
																				<Radio
																					value="radio"
																					onClick={() =>
																						handleClickQuestionType(
																							name,
																							"radio"
																						)
																					}
																				>
																					Radio (Button)
																				</Radio>
																				<Radio
																					value="checkbox"
																					onClick={() =>
																						handleClickQuestionType(
																							name,
																							"checkbox"
																						)
																					}
																				>
																					Checkbox
																				</Radio>
																				<Radio
																					value="select"
																					onClick={() =>
																						handleClickQuestionType(
																							name,
																							"select"
																						)
																					}
																				>
																					Select (DropDown)
																				</Radio>
																				<Radio value="date">
																					Date (Datepicker)
																				</Radio>
																				<Radio value="break">
																					{"Break / Note"}
																				</Radio>
																			</Space>
																		</Radio.Group>
																	</Form.Item>
																</div>
															</div>

															<div className="btn-delete-wrapper">
																<Button
																	type="link"
																	icon={<FontAwesomeIcon icon={faTrashAlt} />}
																	onClick={() => remove(name)}
																	className="btn-delete"
																/>
															</div>
														</div>
													);
												}}
											</Form.Item>
										);
									})}

									<div>
										<Button
											icon={<FontAwesomeIcon icon={faPlus} />}
											onClick={() => add()}
											className="btn-main-primary outlined"
										>
											ADD QUESTION
										</Button>
									</div>
								</>
							)}
						</Form.List>
					</Card>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Button
						htmlType="submit"
						className="btn-main-primary"
						loading={isLoadingFormCategory}
					>
						Submit
					</Button>
				</Col>
			</Row>

			<ModalFormQuestionTypeOption
				toggleModalFormQuestionTypeOption={toggleModalFormQuestionTypeOption}
				setToggleModalFormQuestionTypeOption={
					setToggleModalFormQuestionTypeOption
				}
				form={form}
				dataSource={dataSource}
			/>
		</Form>
	);
}
