import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Collapse, Row, Typography } from "antd";
import Highcharts from "highcharts";

import { GET } from "../../../providers/useAxiosQuery";
import highchartsSetOptions from "../../../providers/highchartsSetOptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faAngleDown,
	faAngleUp,
	faArrowLeft,
} from "@fortawesome/pro-regular-svg-icons";

require("highcharts/modules/accessibility")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/boost")(Highcharts);

export default function PageEvaluationCategoryView() {
	const params = useParams();
	const navigate = useNavigate();

	highchartsSetOptions(Highcharts);

	const [dataSource, setDataSource] = useState([]);

	useEffect(() => {
		const linkSurveyViewFn = () => {
			navigate(`/evaluation/view/${params.id}`);
		};
		let linkSurveyView = document.querySelector(".link_view_evaluation");
		if (linkSurveyView) {
			linkSurveyView.addEventListener("click", linkSurveyViewFn);
		}

		return () => {
			if (linkSurveyView) {
				linkSurveyView.removeEventListener("click", linkSurveyViewFn);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (params.category_id) {
		GET(
			`api/form_question_category/${params.category_id}`,
			"form_question_category_info",
			(res) => {
				console.log("form_question_category", res);
				if (res.data) {
					let data = res.data;

					setDataSource(data);
				}
			}
		);
	}

	const renderQuestion = () => {
		if (
			dataSource &&
			dataSource.form_questions &&
			dataSource.form_questions.length
		) {
			const defaultActiveKey = [];

			const items = dataSource.form_questions.map((item, index) => {
				defaultActiveKey.push(index);
				const form_question_options = [];

				if (item.form_question_options && item.form_question_options.length) {
					let series_name = [];

					item.form_question_options.forEach((option) => {
						let answer = item.form_question_answers.filter((f) => {
							try {
								const parsedAnswer = JSON.parse(f.answer);
								let isMatch = false;
								parsedAnswer.forEach((p) => {
									if (p === option.option) isMatch = true;
								});
								return isMatch;
							} catch (error) {
								return f.answer === option.option;
							}
						}).length;

						series_name.push({
							name: option.option,
							y: answer,
						});
					});

					setTimeout(() => {
						let graphId = document.querySelector(`#graph_${index}`);
						if (graphId) {
							Highcharts.chart(`graph_${index}`, {
								chart: {
									plotBackgroundColor: null,
									plotBorderWidth: null,
									plotShadow: false,
									type: "pie",
									height: 300,
								},
								title: {
									text: null,
								},
								tooltip: {
									formatter: function () {
										return `<b>${this.key}: ${this.y} answer${
											this.y > 1 ? "'s" : ""
										}`;
									},
								},
								plotOptions: {
									pie: {
										allowPointSelect: true,
										cursor: "pointer",
										dataLabels: {
											enabled: true,
											distance: -50,
											formatter: function () {
												return `<b style="font-size:16px;">${this.y}</b>`;
											},
										},
										showInLegend: true,
									},
								},
								accessibility: {
									enabled: false,
								},
								series: [
									{
										name: `${dataSource && dataSource?.form.form_name} - ${
											dataSource && dataSource?.form.year
										} - ${dataSource && dataSource?.category}`,
										colorByPoint: true,
										data: series_name,
									},
								],
								legend: {
									align: "center",
									verticalAlign: "top",
									layout: "horizontal",
									y: 35,
								},
								exporting: {
									filename: `${dataSource && dataSource?.form.form_name}-${
										dataSource && dataSource?.form.year
									}-${dataSource && dataSource?.category}`,
									buttons: {
										contextButton: {
											align: "center",
											symbolStroke: "#f58d13",
											menuItems: [
												"printChart",
												"separator",
												"downloadPNG",
												"downloadJPEG",
												"downloadPDF",
												"downloadSVG",
												"separator",
												"downloadCSV",
												// "downloadXLS",
											],
										},
									},
								},
							});
						}
					}, 1000);
				} else {
					let series_name = [];
					item.form_question_answers.forEach((option, index2) => {
						let findIndex = series_name.findIndex(
							(f) => f.name === option.answer
						);

						if (findIndex === -1) {
							series_name.push({
								name: option.answer,
								y: 0,
							});
						}
					});
					series_name = series_name.map((item2) => ({
						...item2,
						y: item.form_question_answers.filter((f) => f.answer === item2.name)
							.length,
					}));

					setTimeout(() => {
						let graphId = document.querySelector(`#graph_${index}`);
						if (graphId) {
							Highcharts.chart(`graph_${index}`, {
								chart: {
									plotBackgroundColor: null,
									plotBorderWidth: null,
									plotShadow: false,
									type: "pie",
									height: 300,
								},
								title: {
									text: null,
								},
								tooltip: {
									formatter: function () {
										return `<b>${this.key}: ${this.y} answer${
											this.y > 1 ? "'s" : ""
										}`;
									},
								},
								plotOptions: {
									pie: {
										allowPointSelect: true,
										cursor: "pointer",
										dataLabels: {
											enabled: true,
											distance: -50,
											formatter: function () {
												return `<b style="font-size:16px;">${this.y}</b>`;
											},
										},
										showInLegend: true,
									},
								},
								accessibility: {
									enabled: false,
								},
								series: [
									{
										name: `${dataSource && dataSource?.form.form_name} - ${
											dataSource && dataSource?.form.year
										} - ${dataSource && dataSource?.category}`,
										colorByPoint: true,
										data: series_name,
									},
								],
								legend: {
									align: "center",
									verticalAlign: "top",
									layout: "horizontal",
									y: 35,
								},
								exporting: {
									filename: `${dataSource && dataSource?.form.form_name}-${
										dataSource && dataSource?.form.year
									}-${dataSource && dataSource?.category}`,
									buttons: {
										contextButton: {
											align: "center",
											symbolStroke: "#f58d13",
											menuItems: [
												"printChart",
												"separator",
												"downloadPNG",
												"downloadJPEG",
												"downloadPDF",
												"downloadSVG",
												"separator",
												"downloadCSV",
												// "downloadXLS",
											],
										},
									},
								},
							});
						}
					}, 1000);
				}

				return {
					key: index,
					label: (
						<div
							className="quill-output"
							dangerouslySetInnerHTML={{ __html: item.question }}
						/>
					),
					children: (
						<Row>
							<Col xs={24} sm={24} md={24} lg={24}>
								<Typography.Text className="title"></Typography.Text>
								{form_question_options}
								<div id={`graph_${index}`}></div>
							</Col>
						</Row>
					),
				};
			});

			return (
				<Collapse
					items={items}
					defaultActiveKey={defaultActiveKey}
					expandIcon={({ isActive }) => (
						<FontAwesomeIcon icon={isActive ? faAngleUp : faAngleDown} />
					)}
					expandIconPosition="end"
				/>
			);
		} else {
			return null;
		}
	};

	return (
		<Row gutter={[12, 12]}>
			<Col xs={24} sm={24} md={24} lg={24}>
				<Button
					icon={<FontAwesomeIcon icon={faArrowLeft} />}
					onClick={() => navigate(-1)}
				>
					BACK
				</Button>
			</Col>

			<Col xs={24} sm={24} md={24} lg={24}>
				<Card title={dataSource.category}>{renderQuestion()}</Card>
			</Col>
		</Row>
	);
}
