import { Routes, Route } from "react-router-dom";
import {
	faHome,
	faUsers,
	faPenToSquare,
	faPieChart,
	faMobile,
} from "@fortawesome/pro-regular-svg-icons";

import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

import Page404 from "../views/errors/Page404";
import PageRequestPermission from "../views/errors/PageRequestPermission";

import PageLogin from "../views/public/PageLogin/PageLogin";

import PageEditProfile from "../views/private/PageEditProfile/PageEditProfile";

import PageDashboard from "../views/private/PageDashboard/PageDashboard";
import PageEvaluation from "../views/private/PageEvaluation/PageEvaluation";
import PageReports from "../views/private/PageReports/PageReports";
import PageMobileAPK from "../views/private/PageMobileAPK/PageMobileAPK";
import PageEvaluationView from "../views/private/PageEvaluation/PageEvaluationView";
import PageEvaluationCategoryForm from "../views/private/PageEvaluation/PageEvaluationCategoryForm";
import PageEvaluationCategoryView from "../views/private/PageEvaluation/PageEvaluationCategoryView";

export default function RouteList() {
	return (
		<Routes>
			<Route
				path="/"
				element={
					<PublicRoute title="LOGIN" pageId="PageLogin" component={PageLogin} />
				}
			/>
			<Route
				path="/edit-profile"
				element={
					<PrivateRoute
						moduleName="Edit Profile"
						title="User"
						subtitle="VIEW / EDIT"
						pageId="PageUserProfile"
						pageHeaderIcon={faUsers}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Edit Profile",
							},
						]}
						component={PageEditProfile}
					/>
				}
			/>
			<Route
				path="/dashboard"
				element={
					<PrivateRoute
						moduleCode="M-01"
						moduleName="Dashboard"
						title="Dashboard"
						subtitle="ADMIN"
						pageId="PageDashboard"
						pageHeaderIcon={faHome}
						breadcrumb={[
							{
								name: "Dashboard",
							},
						]}
						component={PageDashboard}
					/>
				}
			/>

			<Route
				path="/evaluation"
				element={
					<PrivateRoute
						moduleCode="M-02"
						moduleName="Evaluation"
						title="Evaluation"
						subtitle="LIST"
						pageId="PageEvaluation"
						pageHeaderIcon={faPenToSquare}
						breadcrumb={[
							{
								name: "Evaluation",
							},
						]}
						component={PageEvaluation}
					/>
				}
			/>

			<Route
				path="/evaluation/view/:id"
				element={
					<PrivateRoute
						moduleCode="M-03"
						moduleName="Evaluation - View"
						title="Evaluation"
						subtitle="VIEW"
						pageId="PageEvaluationView"
						pageHeaderIcon={faPenToSquare}
						breadcrumb={[
							{
								name: "Evaluation",
								link: "/evaluation",
							},
							{
								name: "View Evaluation",
							},
						]}
						component={PageEvaluationView}
					/>
				}
			/>

			<Route
				path="/evaluation/view/:id/category/add"
				element={
					<PrivateRoute
						moduleCode="M-04"
						moduleName="Evaluation Category - Add"
						title="Category"
						subtitle="ADD EVALUATION"
						pageId="PageEvaluationCategoryForm"
						pageHeaderIcon={faPenToSquare}
						breadcrumb={[
							{
								name: "Evaluation",
								link: "/evaluation",
							},
							{
								name: "View Evaluation",
							},
						]}
						component={PageEvaluationCategoryForm}
					/>
				}
			/>

			<Route
				path="/evaluation/view/:id/category/edit/:category_id"
				element={
					<PrivateRoute
						moduleCode="M-05"
						moduleName="Evaluation Category - Edit"
						title="Category"
						subtitle="EDIT EVALUATION"
						pageId="PageEvaluationCategoryForm"
						pageHeaderIcon={faPenToSquare}
						breadcrumb={[
							{
								name: "Evaluation",
								link: "/evaluation",
							},
							{
								name: "View Evaluation",
								className: "link_view_evaluation",
							},
							{
								name: "Edit Category",
							},
						]}
						component={PageEvaluationCategoryForm}
					/>
				}
			/>

			<Route
				path="/evaluation/view/:id/category/view/:category_id"
				element={
					<PrivateRoute
						moduleCode="M-06"
						moduleName="Evaluation Category - View"
						title="Category"
						subtitle="VIEW EVALUATION"
						pageId="PageEvaluationCategoryView"
						pageHeaderIcon={faPenToSquare}
						breadcrumb={[
							{
								name: "Evaluation",
								link: "/evaluation",
							},
							{
								name: "View Evaluation",
								className: "link_view_evaluation",
							},
							{
								name: "View Category",
							},
						]}
						component={PageEvaluationCategoryView}
					/>
				}
			/>

			<Route
				path="/reports"
				element={
					<PrivateRoute
						moduleCode="M-07"
						moduleName="Reports"
						title="Reports"
						subtitle="LIST"
						pageId="PageReports"
						pageHeaderIcon={faPieChart}
						breadcrumb={[
							{
								name: "Reports",
							},
						]}
						component={PageReports}
					/>
				}
			/>

			<Route
				path="/mobile-apk"
				element={
					<PrivateRoute
						moduleCode="M-08"
						moduleName="Mobile APK"
						title="Mobile"
						subtitle="APK"
						pageId="PageMobileApk"
						pageHeaderIcon={faMobile}
						breadcrumb={[
							{
								name: "Mobile APK",
							},
						]}
						component={PageMobileAPK}
					/>
				}
			/>
			{/* SETTINGS */}
			<Route path="/request-permission" element={<PageRequestPermission />} />
			<Route path="*" element={<Page404 />} />
		</Routes>
	);
}
