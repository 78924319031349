import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { Button, Col, Row, notification } from "antd";

import { DELETE, GET, POST } from "../../../providers/useAxiosQuery";
import {
	TableGlobalSearchAnimated,
	TablePageSize,
	TablePagination,
	TableShowingEntriesV2,
} from "../../../providers/CustomTableFilter";
import TableEvaluation from "./components/TableEvaluation";
import ModalForm from "./components/ModalForm";
import notificationErrors from "../../../providers/notificationErrors";

export default function PageEvaluation() {
	const [toggleModalForm, setToggleModalForm] = useState({
		open: false,
		data: null,
	});

	const [tableFilter, setTableFilter] = useState({
		page: 1,
		page_size: 50,
		search: "",
		sort_field: "created_at_formatted",
		sort_order: "desc",
	});

	const { data: dataUserRoles } = GET(
		`api/user_role`,
		"user_role_select",
		(res) => {},
		false
	);
	const { data: dataSchoolYear } = GET(
		`api/ref_school_year`,
		"school_year_select",
		(res) => {},
		false
	);
	const { data: dataSemester } = GET(
		`api/ref_semester`,
		"semester_select",
		(res) => {},
		false
	);

	const { data: dataSource, refetch: refetchSource } = GET(
		`api/form?${new URLSearchParams(tableFilter)}`,
		"form_list"
	);

	const onChangeTable = (pagination, filters, sorter) => {
		setTableFilter({
			...tableFilter,
			sort_field: sorter.columnKey,
			sort_order: sorter.order ? sorter.order.replace("end", "") : null,
			page: 1,
			page_size: "50",
		});
	};

	const { mutate: mutateChangeStatus, isLoading: isLoadingChangeStatus } = POST(
		"api/form_change_status",
		"form_list"
	);

	const handleChangeStatus = (values) => {
		mutateChangeStatus(values, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Form",
						description: res.message,
					});
				} else {
					notification.error({
						message: "Form",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	const { mutate: mutateDeleteForm, isLoading: isLoadingDeleteForm } = DELETE(
		"api/form",
		"form_list"
	);

	const handleDelete = (values) => {
		mutateDeleteForm(values, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Evaluation",
						description: res.message,
					});
				} else {
					notification.error({
						message: "Evaluation",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	useEffect(() => {
		refetchSource();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableFilter]);

	return (
		<Row gutter={[12, 12]} id="tbl_wrapper">
			<Col xs={24} sm={24} md={24} lg={24} xl={24}>
				<Button
					icon={<FontAwesomeIcon icon={faPlus} />}
					className="w-auto btn-main-primary"
					onClick={() =>
						setToggleModalForm({
							open: true,
							data: null,
						})
					}
				>
					Add Form
				</Button>
			</Col>

			<Col xs={24} sm={24} md={24} lg={24} xl={24}>
				<div className="tbl-top-filter">
					<div
						style={{
							display: "flex",
							gap: 8,
						}}
					>
						<TableGlobalSearchAnimated
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
						/>
					</div>

					<div
						style={{
							display: "flex",
							gap: 12,
						}}
					>
						<TableShowingEntriesV2 />

						<TablePageSize
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
						/>
					</div>
				</div>
			</Col>

			<Col xs={24} sm={24} md={24} lg={24} xl={24}>
				<TableEvaluation
					dataSource={dataSource}
					onChangeTable={onChangeTable}
					handleChangeStatus={handleChangeStatus}
					handleDelete={handleDelete}
					isLoadingDeleteForm={isLoadingDeleteForm}
					isLoadingChangeStatus={isLoadingChangeStatus}
					setToggleModalForm={setToggleModalForm}
				/>
			</Col>

			<Col xs={24} sm={24} md={24} lg={24} xl={24}>
				<div className="tbl-bottom-filter">
					<TableShowingEntriesV2 />
					<TablePagination
						tableFilter={tableFilter}
						setTableFilter={setTableFilter}
						setPaginationTotal={dataSource?.data.total}
						showLessItems={true}
						showSizeChanger={false}
						tblIdWrapper="tbl_wrapper"
					/>
				</div>
			</Col>

			<ModalForm
				toggleModalForm={toggleModalForm}
				setToggleModalForm={setToggleModalForm}
				dataUserRoles={dataUserRoles}
				dataSchoolYear={dataSchoolYear}
				dataSemester={dataSemester}
			/>
		</Row>
	);
}
