import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Typography } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";

import { logoFullWidth, name } from "../../providers/companyInfo";
import { adminSideMenu } from "./components/SideMenuList";

export default function Sidemenu(props) {
	const {
		location,
		sideMenuCollapse,
		setSideMenuCollapse,
		width,
		dataPermissions,
	} = props;

	const [openKeys, setOpenKeys] = useState();

	let pathname = location.pathname;
	pathname = pathname.split("/");
	pathname = "/" + pathname[1];

	const [menuItems, setMenuItems] = useState([]);

	useEffect(() => {
		setMenuItems(adminSideMenu);

		return () => {};
	}, []);

	useEffect(() => {
		setOpenKeys(
			menuItems
				.filter((item) => item.path === pathname)
				.map((item) => item.path)
		);
	}, [pathname, menuItems]);

	const onOpenChange = (keys) => {
		const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
		const menuItemsFilter = menuItems
			.filter((item) => item.path === latestOpenKey)
			.map((item) => item.path);

		if (menuItemsFilter.indexOf(latestOpenKey) === -1) {
			setOpenKeys(menuItemsFilter);
		} else {
			setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
		}
	};

	const handleCheckPermission = (moduleCode) => {
		return dataPermissions.some(
			(f) =>
				f.module_code === moduleCode &&
				f.module_buttons.some(
					(f2) =>
						f2.mod_button_code === "view_page" && parseInt(f2.status) === 1
				)
		);
	};

	const activeRoute = (routeName) => {
		const pathname = location.pathname;
		return pathname === routeName ? "ant-menu-item-selected" : "";
	};

	const activeSubRoute = (routeName) => {
		const pathname1 = location.pathname.split("/");
		const pathname2 = routeName.split("/");
		return "/" + pathname1[1] + "/" + pathname1[2] ===
			"/" + pathname2[1] + "/" + pathname2[2]
			? "ant-menu-item-selected"
			: "";
	};

	const createLink = (item, isChild = false) => {
		const { targetNew, path, title, permission } = item;
		const href = path.startsWith("http")
			? path
			: `${window.location.origin}${path}`;
		return targetNew === 1 ? (
			<Typography.Link target="new" href={href}>
				{title ?? permission}
			</Typography.Link>
		) : (
			<Link to={isChild ? item.path : path}>{title ?? permission}</Link>
		);
	};

	const handleMenuRender = () => {
		let items = [];

		menuItems.forEach((item) => {
			const { children, path, icon, title, className, moduleCode, id } = item;
			let link = createLink(item);

			if (children && children.length > 0) {
				let children_list = [];

				children.forEach((item2) => {
					const { path: childPath, moduleCode: childModuleCode } = item2;
					let link = createLink(item2, true);

					if (handleCheckPermission(childModuleCode)) {
						children_list.push({
							key: childPath,
							className: activeSubRoute(childPath),
							label: link,
							onClick: () => {
								if (width < 768) {
									setSideMenuCollapse(true);
								}
							},
						});
					}
				});

				if (children_list.length > 0) {
					items.push({
						key: path,
						icon: icon,
						label: title,
						className: className ?? "",
						children: children_list,
					});
				}
			} else {
				if (handleCheckPermission(moduleCode)) {
					items.push({
						key: path,
						icon: icon,
						label: link,
						className: activeRoute(path) + " " + (className ?? ""),
						id: id,
						onClick: () => {
							if (width < 768) {
								setSideMenuCollapse(true);
							}
						},
					});
				}
			}
		});

		return items;
	};

	return (
		<Layout.Sider trigger={null} collapsible collapsed={sideMenuCollapse}>
			<div className="ant-side-header">
				<MenuUnfoldOutlined
					id="btn_sidemenu_collapse_unfold"
					onClick={() => {
						setSideMenuCollapse(false);
						setTimeout(() => {
							setOpenKeys(
								menuItems
									.filter((item) => item.path === pathname)
									.map((item) => item.path)
							);
						}, 200);
					}}
					style={{ display: sideMenuCollapse ? "block" : "none" }}
				/>
				<MenuFoldOutlined
					id="btn_sidemenu_collapse_fold"
					onClick={() => {
						setSideMenuCollapse(true);
					}}
					style={{ display: !sideMenuCollapse ? "block" : "none" }}
				/>

				<div className="logo_wrapper">
					{!sideMenuCollapse && (
						<img src={logoFullWidth} alt={name} width="150px" />
					)}
				</div>
			</div>

			<Menu
				theme="light"
				mode="inline"
				openKeys={openKeys}
				selectedKeys={[pathname]}
				onOpenChange={onOpenChange}
				items={handleMenuRender()}
			/>
		</Layout.Sider>
	);
}
