import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Row, Typography } from "antd";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { GET } from "../../../providers/useAxiosQuery";
import TableQuestionCategory from "./components/TableQuestionCategory";

export default function PageEvaluationView() {
	const params = useParams();
	const navigate = useNavigate();

	console.log("params:", params);

	const [dataSource, setDataSource] = useState(null);

	if (params.id) {
		GET(`api/form/${params.id}`, "form_info", (res) => {
			if (res.data) {
				setDataSource(res.data);
			}
		});
	}

	return (
		<Row gutter={[12, 12]}>
			<Col xs={24} sm={24} md={24} lg={24}>
				<Button
					icon={<FontAwesomeIcon icon={faArrowLeft} />}
					onClick={() => navigate(`/evaluation`)}
				>
					BACK
				</Button>
			</Col>
			<Col xs={24} sm={24} md={24} lg={24}>
				<Typography.Title level={4} className="m-0">
					Evaluation:{" "}
					<span className="title">{dataSource && dataSource?.form_name}</span> -{" "}
					<span className="year">{dataSource && dataSource?.year}</span>
				</Typography.Title>
			</Col>

			<Col xs={24} sm={24} md={24} lg={24}>
				<TableQuestionCategory form_id={params.id} />
			</Col>
		</Row>
	);
}
