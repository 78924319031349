import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPlus,
	faTrashAlt,
	faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import { Button, Form, Modal, notification } from "antd";

import { DELETE } from "../../../../providers/useAxiosQuery";
import FloatInput from "../../../../providers/FloatInput";
import validateRules from "../../../../providers/validateRules";
import notificationErrors from "../../../../providers/notificationErrors";

export default function ModalFormQuestionTypeOption(props) {
	const {
		toggleModalFormQuestionTypeOption,
		setToggleModalFormQuestionTypeOption,
		form,
		dataSource,
	} = props;
	const [formQuestionTypeOption] = Form.useForm();

	const onFinish = (values) => {
		let form_questions = form.getFieldValue("form_questions");
		if (form_questions.length > 0) {
			form_questions[toggleModalFormQuestionTypeOption.key] = {
				...form_questions[toggleModalFormQuestionTypeOption.key],
				form_question_options: values.form_question_options,
			};

			form.setFieldsValue({ form_questions });
			setToggleModalFormQuestionTypeOption({ open: false, key: null });
		}
	};

	useEffect(() => {
		if (toggleModalFormQuestionTypeOption.open) {
			let form_questions = form.getFieldValue("form_questions");
			if (form_questions.length > 0) {
				let form_question_options =
					form_questions[toggleModalFormQuestionTypeOption.key]
						.form_question_options;
				formQuestionTypeOption.setFieldsValue({
					form_question_options: form_question_options ?? [""],
				});
			}
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toggleModalFormQuestionTypeOption]);

	const { mutate: mutateForm, isLoading: isLoadingForm } = DELETE(
		"api/form_question_option",
		"form_question_category_info"
	);

	const handleDeleteOption = (name, remove) => {
		let dataSourceCopy = { ...dataSource };
		let form_questions =
			dataSourceCopy.form_questions[toggleModalFormQuestionTypeOption.key];
		let form_question_options = form_questions.form_question_options[name];

		if (form_questions) {
			if (form_question_options && form_question_options.id) {
				mutateForm(form_question_options, {
					onSuccess: (res) => {
						if (res.success) {
							notification.success({
								message: "Question Options",
								description: res.message,
							});
							remove(name);
						} else {
							notification.error({
								message: "Question Options",
								description: res.message,
							});
						}
					},
					onError: (err) => {
						notificationErrors(err);
					},
				});
			} else {
				remove(name);
			}
		} else {
			remove(name);
		}
	};

	return (
		<Modal
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			title="Question Options"
			open={toggleModalFormQuestionTypeOption.open}
			footer={null}
			onCancel={() =>
				setToggleModalFormQuestionTypeOption({
					open: false,
					key: null,
				})
			}
			className="modal-question-type-option"
			forceRender
		>
			<Form
				form={formQuestionTypeOption}
				onFinish={onFinish}
				initialValues={{ form_question_options: [""] }}
			>
				<Form.List name="form_question_options">
					{(fields, { add, remove }) => (
						<>
							{fields.map(({ key, name, ...restField }) => (
								<div key={key} className="form-list-item-wrapper">
									<div className="form-item-wrapper">
										<Form.Item
											{...restField}
											name={[name, "option"]}
											rules={[validateRules.required()]}
										>
											<FloatInput
												label="Option"
												placeholder="Option"
												required
											/>
										</Form.Item>

										<Form.Item {...restField} name={[name, "scale"]}>
											<FloatInput label="Scale" placeholder="Scale" required />
										</Form.Item>
									</div>

									<div className="btn-delete-wrapper">
										<Button
											type="link"
											icon={<FontAwesomeIcon icon={faTrashAlt} />}
											onClick={() => handleDeleteOption(name, remove)}
											className="btn-delete"
											loading={isLoadingForm}
										/>
									</div>
								</div>
							))}
							<Form.Item>
								<Button
									type="dashed"
									onClick={() => add()}
									block
									icon={<FontAwesomeIcon icon={faPlus} />}
									className="btn-add"
								>
									ADD QUESTION OPTION
								</Button>
							</Form.Item>
						</>
					)}
				</Form.List>

				<Button htmlType="submit" className="btn-main-primary">
					SUBMIT
				</Button>
			</Form>
		</Modal>
	);
}
